import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCourseById, openMobileAppFeature } from '../../api/utils';
import { IAudio, ICourse, IUser } from '../../types/interfaces';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CoursePreviewTab from './CoursePreviewTab';
import Lessons from './Lessons';
import Resources from './Resources';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Link } from '@mui/material';
import MembershipDescriptionModal from '../Shared/MembershipDescriptionModal/MembershipDescriptionModal';
import { connect } from 'react-redux';
import { PaymentService } from '../../api/paymentsService';

const mapStateToProps = () => ({

});

function CourseDetail() {
	const [openMembershipDescModal, setOpenMembershipDescModal] = useState(false);
	useEffect(() => {
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	}, []);
	const { id } = useParams(); // Capture the course ID from the URL
	const navigate = useNavigate();
	const [courseNotFound, setCourseNotFound] = useState(false);
	const [otherError, setOtherError] = useState(false);

	const [course, setCourse] = useState<ICourse>();
	const [value, setValue] = useState(0); // State for tab value
	const [totalDurationMillis, setTotalDurationMillis] = useState(0);
	const [progressPercent, setProgressPercent] = useState(0);
	const totalDurationInMinutes = Math.round(
		moment.duration(totalDurationMillis).asMinutes()
	);
	const formatDuration = () => {
		if (totalDurationInMinutes >= 60) {
			const hours = Math.floor(totalDurationInMinutes / 60);
			const minutes = totalDurationInMinutes % 60;
			return minutes > 0 ? `${hours} Hr, ${minutes} Min` : `${hours} Hr`;
		} else {
			return `${totalDurationInMinutes} Min`;
		}
	};
	useEffect(() => {
		if (!id) {
			navigate('/app/search');
			return;
		}
		fetchCourseById(+id)
			.then((course) => {
				setCourse(course);
				PaymentService.setMembershipDetails(
					course.membership_id,
					course.membership_price_in_dollars,
					course.tutor_name,
					course?.tutor_profile_image || ''
				);
				const totalDurationMillisecs =
					course?.audios?.reduce((total, audio) => {
						return total + (audio['duration_millisecs'] || 0); // Default to 0 if duration_millisecs is undefined
					}, 0) ?? 0;

				setTotalDurationMillis(totalDurationMillisecs);
			})
			.catch((error) => {
				if (error.status === 404) {
					setCourseNotFound(true);
				} else {
					setOtherError(true);
				}
			});
	}, [id]); // Add id to dependency array

	useEffect(() => {
		const totalProgressMillis = course?.audios?.reduce((a, b) => {
			return a + (b as IAudio).progress_millis;
		}, 0) as number;
		setProgressPercent(
			totalDurationMillis
				? (totalProgressMillis / totalDurationMillis) * 100
				: 0
		);
	}, [course, totalDurationMillis]);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	// Function to render tab content based on selected tab
	const renderTabContent = () => {
		if (!course) {
			return null;
		}
		switch (value) {
			case 0:
				return (
					<CoursePreviewTab
						course={course}
						totalDurationMillis={totalDurationMillis}
					/>
				);
			case 1:
				return <Lessons lessons={course.audios} />;
			case 2:
				return <Resources resources={course.resources} />;
			default:
				return null;
		}
	};

	return (
		<>
			{otherError ? (
				<Alert severity='error'>
					An error occurred while retrieving course.
				</Alert>
			) : courseNotFound ? (
				<Alert severity='error'>Course Not Found.</Alert>
			) : !!course ? (
				<>
					<Box
						sx={{
							m: 'auto',
							mt: '5%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							overflow: 'auto',
						}}>
						<Box
							position='relative' // Allows absolute positioning of the overlay
							sx={{ width: 300, height: 300 }}>
							{/* Image */}
							<img
								src={course?.descriptive_image}
								alt='Course Poster'
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover', // Ensures the image covers the container
									borderRadius: '8px',
								}}
							/>

							{/* Overlay Content */}
							<Box
								sx={{
									position: 'absolute',
									bottom: 0,
									left: 0,
									width: '100%',
									bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
									px: 1, // Padding for the content
									py: 1,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									borderBottomRightRadius: '8px',
									borderBottomLeftRadius: '8px',
								}}>
								<Typography
									variant='h6'
									sx={{
										fontSize: 18,
										color: '#FFFFFF',
										fontWeight: 'bold',
									}}
									noWrap>
									{course?.name}
								</Typography>

								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography
										variant='body1'
										sx={{ fontSize: 16, color: '#FFFFFF' }}>
										{`${course?.audios?.length} Lessons`}
									</Typography>
									<CircleIcon
										fontSize='small'
										sx={{ mx: 1, color: '#FFFFFF', fontSize: 6 }}
									/>
									<Typography
										variant='body1'
										sx={{ fontSize: 16, color: '#FFFFFF' }}>
										{formatDuration()}
									</Typography>
									<Box position='relative' display='inline-flex' sx={{ ml: 2 }}>
										{/* Background CircularProgress (for track) */}
										<CircularProgress
											variant='determinate'
											value={100} // Full circle
											size={35}
											thickness={4}
											sx={{
												color: '#808080', // Background circle color
												position: 'absolute', // To layer underneath
											}}
										/>
										{/* Foreground CircularProgress (for actual progress) */}
										<CircularProgress
											variant='determinate'
											value={progressPercent} // Actual progress percentage
											size={35}
											thickness={4}
											sx={{
												color: '#2F69F8', // Progress bar color
											}}
										/>
										{/* Label */}
										<Box
											position='absolute'
											top='50%'
											left='50%'
											sx={{
												transform: 'translate(-50%, -50%)',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<Typography
												variant='caption'
												component='div'
												sx={{
													color: '#FFFFFF', // Label text color
													fontSize: '12px',
												}}>
												{`${Math.round(progressPercent)}%`}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						{/* Tabs for Preview, Lessons, Resources */}
						<div
							style={{
								margin: 'auto',
								width: '95%',
								alignSelf: 'center',
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label='Course Detail Tabs'
								sx={{ alignSelf: 'center' }}>
								<Tab label='Preview' />
								<Tab label='Lessons' />
								<Tab label='Resources' />
							</Tabs>
							{course.has_access ? null : (
								<Button
									fullWidth
									variant='outlined'
									color='primary'
									disabled={!course.allow_subscriptions}
									sx={{ my: 3 }}
									onClick={() => {
									setOpenMembershipDescModal(true);
								}}>
									Gain Access
								</Button>
							)}
							<MembershipDescriptionModal
								open={openMembershipDescModal}
								handleClose={() => {
									setOpenMembershipDescModal(false);
								}}
								educatorName={course?.tutor_name}
								price={course?.membership_price_in_dollars}
								membershipId={course?.membership_id}
							/>
							<Alert severity='info' sx={{ mt: 2 }}>
								Listen and experience more features on the mobile app.{' '}
								<Link
									component='button'
									variant='body1'
									onClick={() => openMobileAppFeature(`course/${course?.id}`)}
									sx={{ color: '#2F69F8', textDecorationColor: '#2F69F8' }}>
									Open App
								</Link>
							</Alert>
							<Box sx={{ mt: -2, pb: 2, width: '100%' }}>
								{renderTabContent()}
							</Box>
						</div>
					</Box>
				</>
			) : null}
		</>
	);
}

export default connect(mapStateToProps)(CourseDetail);
