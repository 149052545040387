import React, { useState } from 'react';
import { Box, Typography, Alert, Link } from '@mui/material';
import { connect } from 'react-redux';
import { IStore, IUser } from '../../types/interfaces';
import AccountTab from './AccountTab';
import ManageSubscriptionsTab from './ManageSubscriptionsTab';
import { styled } from '@mui/material/styles';
import { openMobileAppFeature } from '../../api/utils';

interface StateProps {
	userData: IUser | null;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const CustomTabs = styled(Box)({
	borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	display: 'flex',
	gap: '16px',
});

const CustomTab = styled(Box)<{ active: boolean }>(({ active }) => ({
	padding: '12px 16px',
	cursor: 'pointer',
	position: 'relative',
	textAlign: 'center',
	flex: 1,
	'&::after': {
		content: '""',
		position: 'absolute',
		bottom: -1,
		left: 0,
		right: 0,
		height: '2px',
		background: active ? '#1976d2' : 'transparent',
		transition: 'background-color 0.3s',
	},
}));

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`settings-tabpanel-${index}`}
			aria-labelledby={`settings-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 1, pt: 3 }}>{children}</Box>}
		</div>
	);
}

function Settings({ userData }: StateProps) {
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}>
			<Typography component='h1' variant='h5' sx={{ mb: 3, fontSize: 30 }}>
				Settings
			</Typography>
			<Alert severity='info' sx={{ mt: 1, mb: 2 }}>
				Access more settings on the mobile app.{' '}
				<Link
					component='button'
					variant='body1'
					sx={{ color: '#2F69F8', textDecorationColor: '#2F69F8' }}
					onClick={() => openMobileAppFeature('settings')}>
					Open App
				</Link>
			</Alert>
			<Box sx={{ width: '100%' }}>
				<CustomTabs>
					<CustomTab
						active={selectedTab === 0}
						onClick={() => setSelectedTab(0)}>
						Account
					</CustomTab>
					<CustomTab
						active={selectedTab === 1}
						onClick={() => setSelectedTab(1)}>
						Subscriptions
					</CustomTab>
				</CustomTabs>

				<TabPanel value={selectedTab} index={0}>
					<AccountTab userData={userData} />
				</TabPanel>

				<TabPanel value={selectedTab} index={1}>
					<ManageSubscriptionsTab userData={userData} />
				</TabPanel>
			</Box>
		</Box>
	);
}

const mapStateToProps = (state: IStore): StateProps => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	// Map dispatch actions here
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
