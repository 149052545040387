import { setEducatorProfileData, setUserData } from '../store/actions';
import { store } from '../store/store';
import {
	ICourse,
	IEducatorProfileData,
	IStore,
	ISubscription,
	IUser,
	ToasterType,
} from '../types/interfaces';
import { axiosDefault } from './axios';
import {
	CANCEL_SUBSCRIPTION_URL,
	GET_LIVE_COURSES_URL,
	MEMBERS_URL,
	USER_SUBSCRIPTIONS_URL,
	SUBSCRIPTIONS_URL,
	USERS_URL,
	RESUME_SUBSCRIPTION_URL,
} from './constants';
import { ToasterService } from '../MainUtils/ToasterService';
import * as VariableConstants from '../types/constants';
import { ToasterDuration } from '../types/constants';

export class ProfileService {
	/**
	 * @param {Number} page the max number of members to load
	 * it loads the list of the user's members and store it in the members property in the store
	 */
	public static async loadMembers(page: number) {
		//the user data is what we need here
		const res = await axiosDefault.get(
			`${MEMBERS_URL}?size=${page}&ordering=-created_at`
		);
		const members: IUser[] = res?.data.results;
		return members;
	}

	public static async cancelSubscription(
		subscriptionId: number
	): Promise<ISubscription[]> {
		const res = await axiosDefault.post(CANCEL_SUBSCRIPTION_URL, {
			subscription_id: subscriptionId,
		});
		if (res.data.error) {
			throw new Error(res.data.error);
		}
		return res.data?.subscriptions;
	}
	public static async resumeSubscription(
		subscriptionId: number
	): Promise<ISubscription[]> {
		const res = await axiosDefault.post(
			`${RESUME_SUBSCRIPTION_URL}/${subscriptionId}`
		);
		if (res.data.error) {
			throw new Error(res.data.error);
		}
		return res.data?.subscriptions;
	}
	public static async loadSubscribedEducators(): Promise<ISubscription[]> {
		const res = await axiosDefault.get(USER_SUBSCRIPTIONS_URL);
		console.log('res', res.data);
		return res.data.subscriptions;
	}

	/**
	 * loadCoursesByEducator
	 */
	public static async loadCoursesByEducator(
		educatorId: number,
		page: number,
		isDrafts: boolean
	) {
		const res = await axiosDefault(
			`${GET_LIVE_COURSES_URL}?type=${
				isDrafts ? 'draft' : 'live'
			}&user_id=${educatorId}&size=${page}&ordering=-created_at`
		);
		const courses: ICourse[] = res?.data.results;
		const count = res?.data?.count;
		return { courses, count };
	}

	// for profile screen
	public static async updateProfileCourseDataById(
		userId: number,
		page: number,
		isNotModal: boolean,
		isDrafts: boolean
	) {
		const { courses, count } = await this.loadCoursesByEducator(
			userId,
			page,
			isDrafts
		);

		store.dispatch(
			setEducatorProfileData(
				isDrafts
					? { drafts: courses, draftsCount: count }
					: { courses, coursesCount: count }
			)
		);
	}

	private static async getProfileDataById(
		userId: number,
		pageForLiveCourses: number = VariableConstants.DEFAULT_PAGINATION_COUNT,
		pageForDrafts: number = VariableConstants.DEFAULT_PAGINATION_COUNT
	): Promise<IEducatorProfileData> {
		const courses = await this.loadCoursesByEducator(
			userId,
			pageForLiveCourses,
			false
		);
		const drafts = await this.loadCoursesByEducator(
			userId,
			pageForDrafts,
			true
		);
		return {
			courses: courses.courses,
			coursesCount: courses.count,
			drafts: drafts.courses,
			draftsCount: drafts.count,
		};
	}
	public static async getAndSetUserDataInStore(userId: number) {
		const res = await axiosDefault(USERS_URL + userId);
		store.dispatch(setUserData(res?.data));
	}

	// for profile screen
	public static async loadAndStoreProfileData() {
		const { userData } = store.getState();
		await this.getAndSetUserDataInStore(userData?.id);
		if (!userData?.is_tutor) {
			return;
		}

		const educatorProfileData = await this.getProfileDataById(userData?.id);
		store.dispatch(setEducatorProfileData(educatorProfileData));
	}
	public static clearUserDataFromStore() {
		store.dispatch(setUserData(undefined));
	}
	// for profile screen - this will be called when a course is published or unpublished
	public static async updateAndProfileCoursesAndDrafts(
		pageForLiveCourses: number = VariableConstants.DEFAULT_PAGINATION_COUNT,
		pageForDrafts: number = VariableConstants.DEFAULT_PAGINATION_COUNT
	) {
		const userData = (store.getState() as IStore).userData as IUser;
		const educatorProfileData = await this.getProfileDataById(
			userData.id,
			pageForLiveCourses,
			pageForDrafts
		);
		store.dispatch(setEducatorProfileData(educatorProfileData));
	}

	public static async fetchUserById(userId: number): Promise<IUser> {
		const res = await axiosDefault.get(USERS_URL + userId);
		return res.data;
	}

	public static async deleteAccount(userId: number): Promise<void> {
		try {
			const res = await axiosDefault.delete(USERS_URL + userId);
			return res.data;
		} catch (error: any) {
			if (error?.response?.data?.detail) {
				ToasterService.dispatchAddToaster({
					message: error.response.data.detail,
					type: ToasterType.ERROR,
					duration: ToasterDuration.SHORT,
				});
			} else {
				ToasterService.dispatchAddToaster({
					message:
						'Sorry, an error occurred while deleting your account, please try again later.',
					type: ToasterType.ERROR,
					duration: ToasterDuration.SHORT,
				});
			}
		}
	}
}
