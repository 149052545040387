import axios, {
	AxiosError,
	AxiosInstance,
	CancelTokenStatic,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from 'axios';
import {
	getTokens,
	getTokensExpirationStatus,
	storeTokens,
} from '../store/asyncStorage';
import {
	API_KEY,
	API_BASE_URL,
	REFRESH_URL,
	RESET_PASSWORD_URL,
	USERS_URL,
} from './constants';
import { store } from '../store/store';
import { performSignOut } from '../store/actions';
import { ToasterDuration } from '../types/constants';
import { ToasterType } from '../types/interfaces';
import { ToasterService } from '../MainUtils/ToasterService';

export const showNetworkErrorMessageIfNetworkError = (
	error: ErrorEvent | AxiosError
) => {
	if (error.message === 'Network Error') {
		ToasterService.dispatchAddToaster({
			message: 'Network Error Occurred, check your internet connection.',
			duration: ToasterDuration.SHORT,
			type: ToasterType.ERROR,
		});
		return;
	}
};

export const refreshTokens = async () => {
	const tokens = getTokens();
	try {
		const response = await axiosWithNoAuth.post(REFRESH_URL, {
			refresh: tokens?.refresh,
		});
		storeTokens(response?.data);
	} catch (err) {
		ToasterService.dispatchAddToaster({
			message: 'Sorry an error occurred, logging you out!',
			duration: ToasterDuration.SHORT,
			type: ToasterType.ERROR,
		});
		store.dispatch(performSignOut());
	}
};

const requestConfigInterceptor = async (config: InternalAxiosRequestConfig) => {
	const { accessExpired, refreshExpired } = getTokensExpirationStatus() as {
		accessExpired: boolean;
		refreshExpired: boolean;
		isUserLoggedIn: boolean;
	};

	if (accessExpired && refreshExpired) {
		store.dispatch(performSignOut());
		return config;
	} else if (accessExpired && !refreshExpired) {
		await refreshTokens();
	}

	const tokens = await getTokens();

	// Ensure headers is defined before setting values

	config.headers['Authorization'] = `Bearer ${tokens?.access}`;
	config.headers['X-API-KEY'] = API_KEY;

	return config;
};

const requestErrorInterceptor = (error: AxiosError) => {
	showNetworkErrorMessageIfNetworkError(error);
	ToasterService.dispatchAddToaster({
		message: 'Sorry an error occurred!',
		duration: ToasterDuration.SHORT,
		type: ToasterType.ERROR,
	});

	return Promise.reject(error);
};
const requestConfigForAxiosWithNoAuth = async (config) => {
	config.headers.set('X-API-KEY', API_KEY);
	return config;
};
const responseInterceptor = (response: AxiosResponse) => {
	console.log('responseaxios', response);
	return response;
};

const responseErrorInterceptor = async (error: AxiosError<any, any>) => {
	const axiosError = error as AxiosError;
	if (axios.isCancel(error)) {
		// Return early to avoid further processing
		return; // This prevents propagation of the CanceledError
	}
	const { accessExpired, refreshExpired, isUserLoggedIn } =
		getTokensExpirationStatus() as {
			accessExpired: boolean;
			refreshExpired: boolean;
			isUserLoggedIn: boolean;
		};
	if (axiosError?.response?.status === 401) {
		ToasterService.dispatchAddToaster({
			message: 'Session expired, logging you out!',
			duration: ToasterDuration.SHORT,
			type: ToasterType.ERROR,
		});
		store.dispatch(performSignOut());
		return;
	}
	if (isUserLoggedIn && !accessExpired && !refreshExpired) {
		if (!axios.isCancel(error)) {
			if (
				(axiosError?.config?.url?.includes(USERS_URL) &&
					(axiosError.response?.data as any)?.email) ||
				axiosError?.config?.url?.includes(RESET_PASSWORD_URL)
			) {
				return Promise.reject(axiosError);
			}
			ToasterService.dispatchAddToaster({
				message: 'Sorry an error occurred!',
				duration: ToasterDuration.SHORT,
				type: ToasterType.ERROR,
			});
		}
	} else if (isUserLoggedIn) {
		ToasterService.dispatchAddToaster({
			message: 'An error occurred, please close and reopen Memoryze if stuck.',
			duration: ToasterDuration.SHORT,
			type: ToasterType.ERROR,
		});
	}
	return Promise.reject(error);
};

// ... (previous functions remain the same)

interface ExtendedAxiosInstance extends AxiosInstance {
	CancelToken: CancelTokenStatic;
}

const createExtendedAxiosInstance = (config: any): ExtendedAxiosInstance => {
	const instance = axios.create(config) as ExtendedAxiosInstance;
	instance.CancelToken = axios.CancelToken;
	return instance;
};

const axiosForMedia = createExtendedAxiosInstance({
	baseURL: API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	},
});

const axiosDefault = createExtendedAxiosInstance({
	baseURL: API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

const axiosWithNoAuth = createExtendedAxiosInstance({
	baseURL: API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

axiosForMedia.interceptors.request.use(
	requestConfigInterceptor,
	requestErrorInterceptor
);
axiosForMedia.interceptors.response.use(
	responseInterceptor,
	responseErrorInterceptor
);

axiosDefault.interceptors.request.use(
	requestConfigInterceptor,
	requestErrorInterceptor
);
axiosDefault.interceptors.response.use(
	responseInterceptor,
	responseErrorInterceptor
);

axiosWithNoAuth.interceptors.request.use(
	requestConfigForAxiosWithNoAuth,
	requestErrorInterceptor
);
axiosWithNoAuth.interceptors.response.use(responseInterceptor);

export { axiosForMedia, axiosDefault, axiosWithNoAuth };
