import React, { useState } from 'react';
const Presentation = 'images/vectors/forEducators/Presentation.png';

import { perksData, processesData } from './data';
import './ForEducators.css';
import FadeInSection from '../Shared/FadeInSection';
import ScalableSection from '../Shared/ScalableSection';
import Button from '@mui/material/Button';
function ForEducators() {
	const [perks] = useState(perksData);
	const [processes] = useState(processesData);
	const mappedPerks = perks.map(({ perk, description, image }, i) => {
		return (
			<div className='perk-contents' key={i + perk}>
				<div className='perk-image'>
					<img src={image} alt={perk + ' icon'} className='perk-image' />
				</div>

				<div className='perk-texts'>
					<h5 className='perk'>
						{perk}{' '}
						{i === 0 && (
							<p className='beta-icon' title='alpha feature - coming later'>
								coming soon
							</p>
						)}
					</h5>
					<p className='perk-description'>{description}</p>
				</div>
			</div>
		);
	});
	const mappedProcesses = processes.map(
		({ process, description, image }, i) => {
			return (
				<div className='process-contents' key={i + process}>
					<div className='process-image-container'>
						<div className='number-count'>{i + 1}</div>
						<img
							src={image}
							alt={process + ' icon'}
							className='process-image'
						/>
					</div>

					<div className='process-texts'>
						<h5 className='process'>{process}</h5>
						<p className='process-description'>{description}</p>
					</div>
				</div>
			);
		}
	);

	return (
		<main className='home-container'>
			<div className='curved-bg' style={{ marginBottom: '20px' }}>
				<FadeInSection>
					<section className='section-container landing-section'>
						<div className='pitch'>
							<h3 className='pitch-heading'>
								Share Your Knowledge, Amplify Your Voice
							</h3>
							<p className='pitch-paragraph'>
								{`Are you an expert in your field with knowledge to share? Memoryze offers you the perfect platform to turn your expertise into engaging audio courses and get rewarded for it. Your knowledge deserves to be heard—and now, it can be.`}
							</p>
							<Button
								variant='outlined'
								color='secondary'
								className='get-started-btn'
								sx={{ backgroundColor: '#1d58ec', color: '#F4F5F9' }}>
								Join waitlist
							</Button>
						</div>
						<img
							src={Presentation}
							alt='people making a presentation'
							className='top-image'
						/>
					</section>
				</FadeInSection>
			</div>

			<ScalableSection scaleStart={0.8} scaleEnd={1.1}>
				<section className='section-container perks'>
					<h5 className='perks-heading'>{`What's in it for you?`}</h5>
					<div className='perks-contents-container'>{mappedPerks}</div>
				</section>
			</ScalableSection>
			<ScalableSection scaleStart={0.8}>
				<section className='section-container create-share-earn'>
					<h5 className='processes-heading'>
						Create, Share, and Earn in Just a Few Steps
					</h5>
					<div className='processes-contents-container'>{mappedProcesses}</div>
				</section>
			</ScalableSection>
		</main>
	);
}

export default ForEducators;
