import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'; // Use HistoryRouter instead of Router
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import ToastProvider from '../src/Components/Shared/ToasterProvider';
import history from '../src/MainUtils/history';
import { Provider } from 'react-redux';
import { store } from '../src/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { reduxPersistStore } from './store/store';
// In index.js or App.js
import './fonts.css';

// Get the root element
const rootElement = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(rootElement);

// Render the App
root.render(
	<ThemeProvider theme={theme}>
		<ToastProvider>
			<HistoryRouter history={history}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={reduxPersistStore}>
						<App />
					</PersistGate>
				</Provider>
			</HistoryRouter>
		</ToastProvider>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
