import React, { useState } from 'react';
import { educators, learners } from './data';
import FAQ from './FAQ';
import './FAQsPage.css';
import FadeInSection from '../Shared/FadeInSection';

function FAQsPage() {
	const [faqs] = useState({ educators: educators, learners: learners });
	const [forEducators, setForEducators] = useState(true);
	const educatorsFAQs = faqs.educators.map(({ question, answer }, i) => {
		return (
			<FAQ
				key={i + 'eduFaqs'}
				question={question}
				answer={answer}
				id={i + 'educators'}
			/>
		);
	});
	const learnersFAQs = faqs.learners.map(({ question, answer }, i) => {
		return (
			<FAQ
				key={i + 'lnFaqs'}
				question={question}
				answer={answer}
				id={i + 'educators'}
			/>
		);
	});
	return (
		<main className='home-container'>
			<div className='curved-bg curved-bg-blue' style={{ marginBottom: '30px' }}>
				<h1 className='faqs-heading'>Questions We Get Asked... A Lot</h1>
				<img
					src='images/vectors/FAQs.png'
					alt='people chatting'
					className='faqs-image'
				/>

				<div className='btn-container'>
					<button
						role='tab'
						aria-selected={forEducators}
						aria-controls='educators-panel'
						className={
							forEducators ? 'active-btn faq-btn' : 'inactive-btn faq-btn'
						}
						onClick={() => setForEducators(true)}
						onKeyDown={(e) => {
							if (e.key === 'ArrowRight') {
								setForEducators(false);
							}
						}}>
						By Educators
					</button>
					<button
						role='tab'
						aria-selected={!forEducators}
						aria-controls='learners-panel'
						className={
							!forEducators ? 'active-btn faq-btn' : 'inactive-btn faq-btn'
						}
						onClick={() => setForEducators(false)}
						onKeyDown={(e) => {
							if (e.key === 'ArrowLeft') {
								setForEducators(true);
							}
						}}>
						By Learners
					</button>
				</div>
			</div>
			<div>
				<FadeInSection>
					<div className='faqs-container'>
					{forEducators ? educatorsFAQs : learnersFAQs}
					</div>
				</FadeInSection>
			</div>
		</main>
	);
}

export default FAQsPage;
