import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LinkedInCallback = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const handleCallback = () => {
			// Get URL parameters
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get('code');
			const state = urlParams.get('state');
			const error = urlParams.get('error');
			const error_description = urlParams.get('error_description');

			// Check if this is a mobile app
			const isMobileApp = /mobile-app/.test(state || '');

			if (error || error_description) {
				// Handle error case
				if (isMobileApp) {
					// Redirect to mobile app with error
					window.location.href = `memoryze://app/linkedin-auth?error=${error}&error_description=${error_description}`;
				} else {
					// Handle web app error
					navigate('/app/login', {
						state: {
							error: 'LinkedIn authentication failed',
							details: error_description,
						},
					});
				}
				return;
			}

			if (code) {
				if (isMobileApp) {
					// Redirect to mobile app with auth code
					window.location.href = `memoryze://app/linkedin-auth?code=${code}&state=${state}`;
				} else {
					// Handle web authentication
					// You might want to call your backend API here
					// For now, just redirect to login
					navigate('/app/login', {
						state: {
							linkedInCode: code,
							state: state,
						},
					});
				}
			}
		};

		handleCallback();
	}, [navigate]);

	return <div>Processing LinkedIn authentication...</div>;
};

export default LinkedInCallback;
