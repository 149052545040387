import React from 'react';

function TermsOfServices() {
	return (
		<main className='home-container container tp'>
			<h1 className='c21'>
				<span className='c9'>
					<br />
					Terms and Conditions
				</span>
			</h1>
			<p className='c4'>
				<span className='c0'>Last Updated: November 21, 2024</span>
			</p>
			<p className='c4' id='h.gjdgxs'>
				<span className='c0'>
					Welcome to Memoryze! Memoryze, Inc ("Memoryze," "we," or "our") provides a platform 
					that enables users ("Users," "you," or "your") to access and subscribe to audio-based 
					educational courses ("Courses") created by educators. These Terms and Conditions ("Terms") 
					govern your access to and use of Memoryze's website at https://memoryze.co, its subdomains, 
					mobile applications, and any related platforms or services (collectively, the "Site").
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					YOU MUST BE AT LEAST 18 YEARS OLD TO USE THE SITE, OR, IF YOU ARE
					BETWEEN THE AGES OF 13 AND 18, YOU MUST HAVE YOUR PARENT OR GUARDIAN’S
					PERMISSION TO USE THE SITE AND YOU REPRESENT AND WARRANT THAT YOU HAVE
					OBTAINED SUCH PERMISSION. CHILDREN UNDER THE AGE OF 13 MAY NOT USE THE
					SITE OR REGISTER FOR AN ACCOUNT.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					These Terms of Use are entered into by and between you and Memoryze,
					and together with the Privacy Policy and any other documents expressly
					incorporated by reference, govern your access to the Site, as well as
					any content or functionality of the Services, whether as a visitor or
					a registered user. Please read the Terms of Use carefully before you
					start to use the Site.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					BY USING THE SITE, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE
					TERMS OF USE. IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF USE OR THE
					PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE SITE.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>ELIGIBILITY TO USE THE SITE</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					You must be at least 18 years old to register for an account or use the Site.
					If you are between 13 and 18 years old, you may use the Site only with the 
					consent and supervision of a parent or guardian, and by doing so, you confirm 
					that you have obtained such permission. Users under the age of 13 are strictly 
					prohibited from accessing or using the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>ACCEPTANCE OF TERMS</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					By accessing or using the Site, you agree to be bound by these Terms and our 
					Privacy Policy (which is incorporated by reference). If you do not agree with 
					these Terms or our Privacy Policy, you must not use the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>SCOPE OF SERVICES</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze offers personalized audio learning experiences by allowing:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Educators to upload pre-recorded audio courses along with supplementary resources.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Learners to subscribe to individual educators and access their Courses on a monthly basis.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					The Services include access to Courses and other related content, information, 
					and functionalities provided on the Site (collectively, "Content"). However, 
					learner subscriptions are limited to the content created by the educators they 
					select and do not grant access to all Courses or Content on the Site.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>SITE LICENSE</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					We grant you a limited, non-exclusive, non-transferable, and non-sublicensable 
					license to access and use the Memoryze platform (the "Site") and its content 
					solely for personal, non-commercial purposes, subject to these Terms of Service 
					(the "License"). This License allows you to interact with the Site as intended 
					but does not permit redistribution, resale, or unauthorized use of any content 
					or materials.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>OWNERSHIP OF CONTENT</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze Content: All content created or provided by Memoryze, including but 
					not limited to text, graphics, logos, designs, software, and other proprietary 
					materials ("Memoryze Content"), is the sole property of Memoryze and is protected 
					under applicable copyright, trademark, and intellectual property laws.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Educator Content: Educators retain ownership of the audio courses and accompanying 
					resources they upload to the Site ("Educator Content"). By uploading content, 
					Educators grant Memoryze a non-exclusive, worldwide, royalty-free license to host, 
					display, and distribute their content to Learners subscribed to access their courses.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Learner Access: Learners are granted a personal, non-transferable license to access 
					Educator Content from their subscribed Educators. This access is for personal 
					educational use only and may not be shared, reproduced, or distributed in any form.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>THIRD-PARTY SERVICES AND AFFILIATES</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may include embedded third-party content or links (such as
					hyperlinks) to third party websites, resources, content or services
					(including external websites that are framed by the Site as well as
					any advertisements displayed in connection therewith, and affiliate
					marketing links through which we may earn a commission if/when you
					click on or make purchases via affiliate links) that are not owned or
					controlled by Memoryze (collectively, “
				</span>
				<span className='c3'>Third-Party Services</span>
				<span className='c0'>
					”). When you access Third-Party Services, you do so at your own risk.
					Third-Party Services are not under our control, and you acknowledge
					that Memoryze is not responsible or liable for the content, functions,
					accuracy, legality, appropriateness or any other aspect of Third-Party
					Services. If you believe any Third-Party Services violates applicable
					law or may be inappropriate, please notify Memoryze. The inclusion of
					any Third-Party Services does not imply any association between
					Memoryze and their operators. By using the Site, you expressly relieve
					and hold Memoryze harmless from any and all liability arising from
					your use of any Third-Party Services, including any loss or damage
					incurred as a result of any dealings between you and any third
					parties, or as the result of the presence of such Third-Party Services
					on the Site or the failure of such Third-Party Services to function as
					intended. It is your responsibility to evaluate the content and
					usefulness of the information obtained from third parties.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>INTERACTIVE AREAS AND USER CONTENT</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may provide areas where users can upload, share, and interact with 
					content ("Interactive Areas"). By participating in these areas, you acknowledge 
					and agree that:
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Content posted in Interactive Areas may be visible to other users and, in some 
					cases, the public. Your submissions may display your real name unless you select 
					a screen name during registration.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					You retain ownership of your User Content. However, by posting or submitting 
					content, you grant Memoryze a non-exclusive, worldwide, perpetual, royalty-free, 
					transferable, and sublicensable license to use, reproduce, modify, adapt, 
					distribute, and create derivative works from your content to operate, promote, 
					and improve the Site in any media format.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Memoryze does not actively monitor all content in Interactive Areas but reserves 
					the right to intervene, remove content, or restrict user access at its sole 
					discretion.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) COMPLIANCE</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze respects intellectual property rights and complies with the Digital 
					Millennium Copyright Act (the "DMCA") to the best of its ability. At this time, 
					Memoryze has not registered a designated agent with the United States Copyright 
					Office. However, Memoryze takes copyright infringement claims seriously and will 
					act promptly to address alleged violations in accordance with the DMCA.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					If you believe your copyrighted work has been used on the Site in a manner 
					that constitutes infringement, you may submit a written notice to us at 
					hello@memoryze.co with the following information:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Your contact information, including name, address, telephone number, and email address
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Identification of the copyrighted work that you believe has been infringed
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Identification of the material and its location on the Site (e.g., the URL)
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement of your good faith belief that the use is not authorized
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						A statement under penalty of perjury that the information is accurate and 
						that you are the copyright owner or authorized to act on their behalf
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>COURSES, FEES AND PAYMENTS</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze charges fees for accessing certain parts of the Site, particularly 
					for subscribing to educators and their course catalogs ("Courses"). By subscribing 
					to an educator, you gain access to all Courses in their catalog for the duration 
					of your subscription.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Refunds for subscriptions are available upon request within 7 days of the initial 
					purchase, provided that subscription content has not been extensively accessed 
					or downloaded. Beyond this 7-day period, refunds will not be granted under any 
					circumstances.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					Subscriptions initiated on or after the 26th of any month may encounter delays 
					in refund processing due to the platform's payout schedule. Payouts to educators 
					are processed on the 28th of each month.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>Representations and Warranties</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site is available only to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Individuals who are eighteen (18) years or older (or the age of majority as defined by the applicable law in your jurisdiction); or
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Individuals between the ages of thirteen (13) and eighteen (18) who have obtained parental or guardian permission to use the Site.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					By accessing and using the Site, you represent and warrant that:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You meet the age requirements or have obtained necessary parental/guardian consent if applicable.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						You have the legal right, authority, and capacity to enter into and comply with these Terms of Use.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Additionally, you warrant that:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Registration Data and User Content: All information you submit, including Registration Data and User Content, is true, accurate, current, and complete.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Ownership of Content: You own all rights or have obtained the necessary permissions for any content or information you upload to the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Non-infringement: Your content does not infringe on third-party rights, including intellectual property, privacy, or publicity rights.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					You acknowledge that you are solely responsible for all Registration Data, User Content, and information you post or upload. Memoryze reserves the right to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Review and Remove Content: Reject, edit, or remove any User Content or information deemed non-compliant with these Terms or inappropriate for any reason.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Do so without notice or liability to you.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>ADVERTISERS ON SITE</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					The Site may display advertisements and sponsorships. Memoryze does not guarantee the accuracy or legality of any advertising or sponsored material and is not liable for:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Errors or inaccuracies in advertisers' or sponsors' submissions.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Any acts or omissions by advertisers or sponsors.
					</span>
				</li>
			</ul>
			<p className='c4'>
				<span className='c0'>
					Advertisers and sponsors are solely responsible for ensuring their content complies with applicable laws and regulations.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>SPECIAL PROMOTIONS</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					From time to time, Memoryze may offer promotional opportunities, sweepstakes, and contests (“Promotions”) to Users. These Promotions:
					* Are subject to the sole discretion of Memoryze.
					* May be activated, modified, or removed at any time without prior notice.
					* Are governed by these Terms of Use and any specific terms provided with the Promotion.
					The liability of Memoryze’s partners concerning such Promotions will be limited as outlined in these Terms of Use.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>COMMUNICATIONS</span>
			</h2>
			<div className='c4'>
				<span className='c0'>
					By providing Memoryze with your email address and using the Site, you consent to receive:
					<ol>
						<li><strong>Service and Administrative Notifications</strong>: Essential announcements related to your use of the Site.</li>
						<li><strong>Marketing Communications</strong>: Advertising and promotional messages from Memoryze and third-party advertising partners, as outlined in our Privacy Policy.</li>
					</ol>
					You may opt out of certain marketing notifications by following the instructions in our Privacy Policy. However, if you do not wish to receive essential service and administrative notifications, your only option is to discontinue use of the Site.
				</span>
			</div>
			<div className='c4'>
				<span className='c0'>
					<b>Third-Party Communications</b>:
					<ul>
						<li>
							<b>Liability Disclaimer:</b> Memoryze is not liable for communications you receive from third parties (“Third-Party Communications”), whether directly or indirectly connected to the Site.
						</li>
						<li>
							<b>User Responsibility:</b> You are solely responsible for evaluating the identity, trustworthiness, and content of any Third-Party Communications and for verifying their source.
						</li>
						<li>
							<b>Release of Liability:</b> By using the Site, you release and hold Memoryze harmless from any loss, damage, or liability resulting from Third-Party Communications or your interactions with third parties.
						</li>
					</ul>
					It is your responsibility to use caution and assess the usefulness of information obtained from third parties.
				</span>
			</div>
			<h2 className='c2'>
				<span className='c9'>DISCLAIMERS</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Your use of the Site is at your own risk. The Site is provided on an “as is” and “as available” basis, with no warranties of any kind, whether express or implied. This includes, but is not limited to:
				</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Implied Warranties:</b> Non-infringement, merchantability, and fitness for a particular purpose.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Content Accuracy:</b> Memoryze does not guarantee the accuracy or completeness of the Site’s content or any third-party content linked to the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>No Liability for:</b>
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						1. <b>Errors or Inaccuracies:</b> In the Site’s content or materials.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						2. <b>Personal Injury or Property Damage:</b> Resulting from your use of the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
						<span className='c0'>
						3. <b>Unauthorized Access:</b> To servers or personal information stored within.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						4. <b>Third-Party Services:</b> Memoryze does not endorse or guarantee services or products offered by third parties through the Site. Transactions with such providers are your responsibility.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Additional Disclaimers:</b>
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Site Availability:</b> Memoryze does not warrant uninterrupted or error-free access to the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						 <b>Results and Reliability:</b> No guarantees are made regarding outcomes, timeliness, or reliability of content accessed through the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>User Conduct:</b> Memoryze is not responsible for the actions of users, whether online or offline.
					</span>
				</li>
					<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Malware Protection:</b> The Site is not warranted to be free from viruses, worms, or harmful components.
					</span>
				</li>
					<li className='c15 li-bullet-0'>
					<span className='c0'>
						<b>Data Security:</b> Memoryze does not guarantee the security of personal information against interception, misappropriation, or unauthorized use.
					</span>
				</li>
			
			</ul>
			<p className='c4'>
				<span className='c0'>
					<b>California Civil Code § 1542 Waiver:</b>
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					To the extent applicable, you waive the protections of California Civil Code § 1542 (and similar laws in other jurisdictions), which states:
				</span>
			</p>
			<p className='c4' style={{ marginLeft: '20px' }}>
				<span className='c0'>
					“A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor.”
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>LIMITATION OF LIABILITY</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					YOU ACKNOWLEDGE AND AGREE THAT MEMORYZE IS ONLY WILLING TO PROVIDE THE SITE UNDER 
					 CERTAIN LIMITATIONS OF LIABILITY. UNDER NO CIRCUMSTANCES WILL MEMORYZE OR ITS 
					AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, 
					OR EXEMPLARY DAMAGES ARISING FROM THE USE OR INABILITY TO USE THE SITE, EVEN IF 
					WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
				</span>
			</p>
			<p className='c4'>
				<span className='c0'>
					IN THE EVENT THAT MEMORYZE OR ITS AFFILIATES ARE FOUND LIABLE, THE TOTAL LIABILITY 
					(WHETHER ARISING IN CONTRACT, TORT, OR OTHERWISE) WILL NOT EXCEED $100.00 USD.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>GOVERNING LAW AND JURISDICTION</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					These Terms shall be governed by the laws of the State of Delaware, USA, without 
					regard to conflict of law principles. Any disputes shall first be resolved by 
					mediation, and if unsuccessful, by final and binding arbitration administered by 
					the American Arbitration Association (AAA) in accordance with its Commercial 
					Arbitration Rules.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>LIMITATIONS ON SITE USE</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					When using the Site, you are solely responsible for your interactions, including 
					those in Interactive Areas, and must conduct yourself professionally and respectfully 
					at all times. Your use of the Site, its Content, and other Users' User Content 
					is subject to the following restrictions:
				</span>
			</p>
			<p className='c4'>
				<span className='c9'>Prohibited Conduct</span>
			</p>
			<ul className='c11'>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Do not defame, harass, stalk, threaten, or violate the legal rights of Memoryze 
						or other Users, whether on or off the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Avoid uploading or sharing content that is defamatory, obscene, unlawful, or 
						promotes racism, hatred, or physical harm.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Do not violate any local, state, federal, or international laws while using the Site.
					</span>
				</li>
				<li className='c15 li-bullet-0'>
					<span className='c0'>
						Do not use the Site for advertising, commercial purposes, or unauthorized 
						solicitation unless agreed upon with Memoryze.
					</span>
				</li>
			</ul>
			<h2 className='c2'>
				<span className='c9'>ASSIGNMENTS</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Memoryze may assign, sub-contract, and/or otherwise transfer any or
					all of our rights and/or obligations under these Terms of Use to any
					company, firm, or person. You may not transfer your rights or
					obligations under these Terms of Use to anyone else.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>
					WAIVER AND SEVERABILITY OF TERMS; ENTIRE AGREEMENT
				</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Failure by Memoryze to enforce any provision(s) of these Terms of Use
					will not be construed as a waiver of any provision or right. If any
					provision of these Terms of Use is deemed unlawful, void, or
					unenforceable by a court of law exercising proper jurisdiction, that
					provision will be deemed severed from the remaining provisions and
					will not affect their validity and enforceability. These Terms of Use
					incorporate by reference any notices contained on the Site and the
					Privacy Policy and constitute the entire agreement with respect to
					access to and use of the Site, Site Content, Interactive Areas, and
					User Content.
				</span>
			</p>
			<h2 className='c2'>
				<span className='c9'>CONTACT</span>
			</h2>
			<p className='c4'>
				<span className='c0'>
					Questions or comments about the Site or our services or these Terms of
					Use should be sent by email to{' '}
				</span>
				<span className='c18'>
					<a className='c19' href='mailto:hello@memoryze.co'>
						hello@memoryze.co
					</a>
				</span>
				<span className='c0'>.</span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c7 c14'>
				<span className='c12'></span>
			</p>
			<p className='c14 c7'>
				<span className='c12'></span>
			</p>
			<p className='c4 c7'>
				<span className='c0'></span>
			</p>
			<p className='c7 c20'>
				<span className='c0'></span>
			</p>
		</main>
	);
}

export default TermsOfServices;
