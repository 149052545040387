export const perksData = [
	{
		perk: 'Get paid',
		description:
			"Earn royalties from your courses the same way artists earn from their music. The more learners you get, the more money you earn. It's that simple.",
		image: '/images/vectors/forEducators/GetPaid.png', // Updated path
	},
	{
		perk: 'Amplify Your Voice',
		description:
			'Your insights and knowledge are valuable. Memoryze gives you the platform to share your wisdom with a global audience—because the world deserves to hear what you have to say.',
		image: '/images/vectors/forEducators/GetHeard.png', // Updated path
	},
	{
		perk: 'Inspire and Educate',
		description:
			"There's nothing more fulfilling than knowing you've made a difference in someone's life. As an educator on Memoryze, you help learners achieve their goals while leaving a lasting impact.",
		image: '/images/vectors/forEducators/TeachLearners.png', // Updated path
	},
	{
		perk: 'Build Your Global Brand',
		description:
			'Establish yourself as a thought leader and expert in your field. While you might not become the next Beyoncé or Dwayne Johnson, you can certainly build a global reputation in your own right.',
		image: '/images/vectors/forEducators/Planet.png', // Updated path
	},
	{
		perk: 'Join a Vibrant Community',
		description:
			'Connect with a diverse network of educators and learners. Share experiences, exchange ideas, and grow together as part of the Memoryze community.',
		image: '/images/vectors/forEducators/SocialMedia.png', // Updated path
	},
	{
		perk: 'Own Your Content',
		description:
			"Retain full ownership of your courses. You control how your content is used—because you’re the one in charge. Your content, your rules.",
		image: '/images/vectors/forEducators/OwnYourRights.png', // Updated path
	},
];

export const processesData = [
	{
		process: 'Sign up',
		description:
			"Click the 'Get Started' button to begin your journey as an educator on Memoryze. It’s quick and easy.",
		image: '/images/vectors/forEducators/SignUp.png', // Updated path
	},
	{
		process: 'Create Your Course',
		description:
			'Start developing your audio courses. Whether it’s your first time or you’re a seasoned educator, we’re here to guide you through the process, from planning to recording.',
		image: '/images/vectors/forEducators/SocialMedia.png', // Updated path
	},
	{
		process: 'Upload and Share',
		description:
			'Once your course is ready, upload it to Memoryze. Promote it to your audience and watch your listener count grow.',
		image: '/images/vectors/forEducators/Upload.png', // Updated path
	},
	{
		process: 'Earn',
		description:
			'As your course gains traction, you start earning. It’s time to enjoy the rewards of your hard work and dedication.',
		image: '/images/vectors/forEducators/Investing.png', // Updated path
	},
];
