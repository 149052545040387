import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { axiosDefault } from '../../../api/axios';
import { CREATE_CHECKOUT_SESSION_URL } from '../../../api/constants';
import { ToasterService } from '../../../MainUtils/ToasterService';
import { ToasterType } from '../../../types/interfaces';
import { ToasterDuration } from '../../../types/constants';

interface Props {
	open: boolean;
	handleClose: () => void;
	educatorName: string;
	price: number;
	membershipId: number;
}

export default function MembershipDescriptionModal({
	open,
	handleClose,
	educatorName,
	price,
	membershipId,
}: Props) {
	const onSubscribeNow = async () => {
		try {
			const { data } = await axiosDefault.post(
				`${CREATE_CHECKOUT_SESSION_URL}/${membershipId}`,
				{ success_url: window.location.href }
			);

			window.location.href = data.url; // Redirect to Stripe checkout
		} catch (error) {
			ToasterService.dispatchAddToaster({
				message: 'Oops! Something went wrong. Please try again later.',
				type: ToasterType.ERROR,
				duration: ToasterDuration.LONG,
			});
		}
		handleClose();
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='membership-description-title'
			aria-describedby='membership-description-content'>
			<Box
				sx={{
					bgcolor: '#fff',
					borderRadius: 5,
					width: '95%', // Width can be adjusted
					maxWidth: 300,
					mx: 'auto', // Center horizontally
					my: '20vh', // Center vertically with some offset
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				<img
					src='/images/subscription-banner.png'
					alt='Subscription banner'
					style={{
						width: '100%',
						height: 120,
						borderRadius: 10,
						marginTop: -2,
					}}
				/>
				<Box
					sx={{
						mt: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						borderBottom: '1px solid #B6B6B64D',
						width: '100%',
						paddingRight: 2,
						paddingLeft: 2,
						pb: 2,
					}}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<CircleIcon sx={{ color: '#121212', fontSize: 10 }} />
						<Typography variant='h6' sx={{ fontSize: 16 }}>
							Educator: {educatorName}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<CircleIcon sx={{ color: '#121212', fontSize: 10 }} />
						<Typography variant='h6' sx={{ fontSize: 16 }}>
							Amount: ${price} / month
						</Typography>
					</Box>
				</Box>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						paddingRight: 20,
						paddingLeft: 20,
					}}>
					<Typography
						id='membership-description-content'
						variant='body2'
						sx={{ mt: 3, fontSize: 14 }}>
						Gain full access to an exclusive library of audio courses, including
						all current lessons and any future releases. Each course is crafted
						to provide deep insights, practical skills, and fresh perspectives
						to help you stay on track with your learning goals.
					</Typography>
				</div>
				<Button
					variant='outlined'
					onClick={onSubscribeNow}
					sx={{ my: 4, width: '90%' }}>
					Subscribe
				</Button>
			</Box>
		</Modal>
	);
}
