import React, { useState } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';

function FAQ({ question, answer, id }: any) {
	const [open, setOpen] = useState(false);
	return (
		<div className='faq-panel'>
			<div className='question-container' onClick={() => setOpen(!open)}>
				<h5 className='question'>{question}</h5>
				{open ? (
					<RiArrowUpSLine
						
						className='arrow'
						size={20}
					/>
				) : (
					<RiArrowDownSLine
						
						className='arrow'
						size={20}
					/>
				)}
			</div>
			<div 
				className={`answer-container ${open ? 'open' : ''}`}
				style={{ 
					maxHeight: open ? '1000px' : '0',
					transition: 'max-height 0.3s ease-in-out',
					overflow: 'hidden'
				}}
			>
				<div id={id} className='answer'>
					{answer}
				</div>
			</div>
		</div>
	);
}

export default FAQ;
