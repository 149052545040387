import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Divider, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { socialSignIn } from '../../api/utils';
import {
	LINKEDIN_CLIENT_ID,
	LINKEDIN_AUTH_BASE_URL,
	APPLE_CLIENT_ID,
} from '../../api/constants';
import { useGoogleLogin } from '@react-oauth/google';
import AppleIcon from '@mui/icons-material/Apple';
import { IUser } from '../../types/interfaces';
const GoogleIcon = '/images/Icons/gmail.png';

interface Props {
	mode?: 'login' | 'signup';
	onSuccess: (res: { user: IUser; access: string; refresh: string }) => void;
}

const getLinkedInRedirectUri = () => {
	return `${window.location.origin}/app/linkedin-auth`;
};

const SocialButton = ({
	provider,
	icon,
	onClick,
	disabled,
	actionText,
}: {
	provider: string;
	icon: string;
	onClick: () => void;
	disabled?: boolean;
	actionText: string;
}) => (
	<Button
		onClick={onClick}
		disabled={disabled}
		fullWidth
		sx={{
			height: '48px',
			borderRadius: '24px',
			border: `2px solid #E5E5E5`,
			color: '#121212',
			textTransform: 'none',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '16px',
			'&:hover': {
				border: `2px solid #E5E5E5`,
				backgroundColor: 'rgba(0, 0, 0, 0.04)',
			},
		}}>
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				justifyContent: 'center',
				position: 'relative',
			}}>
			{provider === 'Apple' ? (
				<AppleIcon
					sx={{
						width: '20px',
						height: '20px',
						position: 'absolute',
						left: '16px',
					}}
				/>
			) : (
				<img
					src={icon}
					alt={provider}
					style={{
						width: '20px',
						height: '20px',
						position: 'absolute',
						left: '16px',
					}}
				/>
			)}
			{`${actionText} with ${provider}`}
		</Box>
	</Button>
);

const SocialSignUpAndLogin: React.FC<Props> = ({
	mode = 'signup',
	onSuccess,
}) => {
	const navigate = useNavigate();
	const actionText = mode === 'signup' ? 'Sign up' : 'Login';
	const [isSigningIn, setIsSigningIn] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [linkedInState, setLinkedInState] = useState('');

	const onSocialSignInSuccess = async (
		provider: 'google-oauth2' | 'linkedin-oauth2' | 'apple-id',
		token: string
	) => {
		try {
			const res = await socialSignIn(provider, token, mode);
			if (res && 'error' in res && typeof res.error === 'string') {
				setError(res.error);
				return;
			}
			if (res && 'user' in res) {
				onSuccess(res);
			}
			handleNavigation();
			return;
		} catch (error) {
			setError('Failed to authenticate with server');
		}
	};

	useEffect(() => {
		// Handle LinkedIn redirect
		const handleLinkedInRedirect = () => {
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get('code');
			const returnedState = urlParams.get('state');

			if (code && returnedState === linkedInState) {
				onSocialSignInSuccess('linkedin-oauth2', code);
			} else if (code) {
				setError('Invalid authentication state. Please try again.');
			}
		};

		handleLinkedInRedirect();
	}, [linkedInState]);

	const handleNavigation = () => {
		const previousPath = document.referrer;
		const isFromApp = previousPath.includes('/app/');
		const isFromLogin = previousPath.endsWith('/app/login');

		if (!isFromApp && !isFromLogin) {
			navigate('/app/search');
		} else {
			navigate(-1); // Go back to previous page
		}
	};

	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			try {
				await onSocialSignInSuccess(
					'google-oauth2',
					tokenResponse.access_token
				);
			} catch (error) {
				setError('Google authentication failed. Please try again.');
			}
		},
		onError: () => {
			setError('Google authentication failed. Please try again.');
		},
	});

	const handleAppleSignIn = async (response: any) => {
		try {
			await onSocialSignInSuccess('apple-id', response.authorization.code);
		} catch (error) {
			console.error('Apple auth error:', error);
			setError('Apple authentication failed. Please try again.');
		}
	};

	const handleButtonPress = async (
		provider: 'google-oauth2' | 'linkedin-oauth2' | 'apple-id'
	) => {
		try {
			setError(null);
			setIsSigningIn(true);

			switch (provider) {
				case 'google-oauth2':
					googleLogin(); // This will trigger the Google OAuth flow
					break;

				case 'linkedin-oauth2':
					const newState =
						Math.random().toString(36).substring(7) + '-mobile-app';
					setLinkedInState(newState);

					const authUrl = `${LINKEDIN_AUTH_BASE_URL}&client_id=${
						LINKEDIN_CLIENT_ID || ''
					}&redirect_uri=${encodeURIComponent(
						getLinkedInRedirectUri()
					)}&scope=r_liteprofile%20r_emailaddress&state=${newState}&response_type=code`;

					window.location.href = authUrl;
					break;

				case 'apple-id':
					window.AppleID.auth.signIn().then(handleAppleSignIn);
					break;
			}
		} catch (error) {
			console.error('Auth error:', error);
			setError('Authentication failed. Please try again.');
		} finally {
			setIsSigningIn(false);
		}
	};

	useEffect(() => {
		window.AppleID.auth.init({
			clientId: APPLE_CLIENT_ID,
			scope: 'name email',
			redirectURI: `${window.location.origin}/app/login`,
			state: 'origin:web',
			usePopup: true,
		});
	}, []);

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: 2,
			}}>
			<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', my: 2 }}>
				<Divider sx={{ flex: 1 }} />
				<Typography sx={{ mx: 2, color: 'text.secondary' }}>or</Typography>
				<Divider sx={{ flex: 1 }} />
			</Box>

			{error && (
				<Alert severity='error' sx={{ width: '100%', mb: 2 }}>
					{error}
				</Alert>
			)}

			<SocialButton
				provider='Google'
				icon={GoogleIcon}
				onClick={() => handleButtonPress('google-oauth2')}
				disabled={isSigningIn}
				actionText={actionText}
			/>

		{/* {	<SocialButton
				provider='LinkedIn'
				icon={LinkedInIcon}
				onClick={() => handleButtonPress('linkedin-oauth2')}
				disabled={isSigningIn}
				actionText={actionText}
			/>} */}

			<SocialButton
				provider='Apple'
				icon=''
				onClick={() => handleButtonPress('apple-id')}
				disabled={isSigningIn}
				actionText={actionText}
			/>
		</Box>
	);
};

export default SocialSignUpAndLogin;
