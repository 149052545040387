// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		// primary: {
		// 	main: '#34F934', // Primary color (green)
		// },
		// secondary: {
		// 	main: '#2F69F8', // Secondary color (blue)
		// },
		background: {
			default: '#F4F5F9', // Default background color
		},
		text: {
			primary: '#121212', // Default text color
			secondary: '#64666C', // Secondary text color
		},
	},
	typography: {
		fontFamily: 'Manrope', // Default font family
		h1: {
			color: '#121212', // Custom color for h1
		},
		body1: {
			color: '#121212', // Custom color for body text
		},
		body2: {
			color: '#121212', // Custom color for body text
		},
		// Add more typography settings as needed
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px', // Custom border radius
					textTransform: 'none', // Prevent uppercase text
					padding: '8px 16px', // Custom padding
					boxShadow: 'none', // Remove default shadow
					fontWeight: 'bold',
					fontSize: 18,
					'&:hover': {
						opacity: 0.8, // Background color on hover
					},
				},
				contained: {
					borderColor: '#121212', // Default background color for contained buttons
					color: '#121212', // Default text color for contained buttons
					borderWidth: 2,
					backgroundColor: '#fffff',
					'&:hover': {
						backgroundColor: '#115293', // Darker shade for hover effect
					},
				},
				outlined: {
					borderColor: '#121212', // Default border color for outlined buttons
					color: '#121212', // Default text color for outlined buttons
					borderWidth: 2,
					backgroundColor: '#34F934',
					'&.Mui-disabled': {
						borderWidth: 2,
						opacity: 0.5, // Set opacity when the button is disabled
						borderColor: '#121212', // Keep border color
						color: '#121212', // Keep text color
						backgroundColor: '#34F934', // Keep background color
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#FFFFFF',
						'& fieldset': {
							borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
						},
						'&:hover fieldset': {
							borderColor: 'black', // Border color on hover
						},
						'&.Mui-focused fieldset': {
							borderColor: '#3f51b5', // Border color when focused
						},
					},
				},
			},
		},
	},
});

export default theme;
