import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import Header from './Components/Shared/Header/Header';
import LandingView from './Components/LandingView/LandingView';
import ForEducators from './Components/ForEducators/ForEducators';
import Footer from './Components/Shared/Footer/Footer';
import FAQsPage from './Components/FAQsPage/FAQsPage';
import TermsOfServices from './Components/LegalPages/TermsOfServices';
import PrivacyPolicy from './Components/LegalPages/PrivacyPolicy';
import EducatorsForm from './Components/EducatorsForm/EducatorsForm';
import GetWaitList from './Components/GetWaitList/GetWaitList';
import Login from './Components/Login/Login';
import SearchPage from './Components/Search/SearchPage';
import CourseDetail from './Components/CourseDetail/CourseDetail';
import Profile from './Components/Profile/Profile';
import Container from '@mui/material/Container';
import { getTokensExpirationStatus } from './store/asyncStorage';
import { ProfileService } from './api/profileService';
import { ToasterDuration } from './types/constants';
import { ToasterType } from './types/interfaces';
import { ToasterService } from './MainUtils/ToasterService';
import Settings from './Components/Settings/Settings';
import LinkedInCallback from './Components/Auth/LinkedInCallback';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './api/constants';

const AppParent = () => {
	return (
		<Container
			maxWidth='xs'
			className='home-container'
			sx={{
					minHeight: '100vh',
					// overflowY: 'scroll',
					// overflow: 'hidden',
					paddingBottom: 30,
			}}>
			<div
				style={{
					backgroundColor: '#F4F5F9',
					paddingTop: 100,
				}}>
				{/* Outlet will render child components */}
				<Outlet />
			</div>
		</Container>
	);
};

function App() {
	const navigate = useNavigate();

	useEffect(() => {
		const checkAuthAndLoadUser = async () => {
			// Handle Stripe redirect first
			const urlParams = new URLSearchParams(window.location.search);
			const stripeStatus = urlParams.get('status');
			
			if (stripeStatus === 'success') {
				ToasterService.dispatchAddToaster({
					message: 'Successfully subscribed!',
					type: ToasterType.SUCCESS,
					duration: ToasterDuration.LONG,
				});
				// Reload user data to reflect new subscription status
				await ProfileService.loadAndStoreProfileData();
			} else if (stripeStatus === 'canceled') {
				ToasterService.dispatchAddToaster({
					message: 'Subscription was canceled.',
					type: ToasterType.INFO,
					duration: ToasterDuration.LONG,
				});
			}

			// Clean up the URL if there's a status parameter
			if (stripeStatus) {
				const newUrl = window.location.pathname;
				window.history.replaceState({}, '', newUrl);
			}

			// Only check auth for /app/* routes
			if (!window.location.pathname.startsWith('/app/')) {
				return;
			}

			const tokenExpStatus = getTokensExpirationStatus();

			if (tokenExpStatus?.isUserLoggedIn && !tokenExpStatus.refreshExpired) {
				// Token is valid, load user data
				await ProfileService.loadAndStoreProfileData();
			} else {
				if (!window.location.pathname.includes('/app/login') && !window.location.pathname.includes('/app/linkedin-auth')) {
					ToasterService.dispatchAddToaster({
						message: 'Your session has expired. Please log in again.',
						type: ToasterType.INFO,
						duration: ToasterDuration.SHORT,
					});
					ProfileService.clearUserDataFromStore();
					// Token expired or missing, redirect to login
					navigate('/app/login');
				}
			}
		};

		checkAuthAndLoadUser();
	}, [navigate]);

	// Only run in production environment
	if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
		// Disable right-click
		document.addEventListener('contextmenu', e => e.preventDefault());

		// Disable common keyboard shortcuts
		document.addEventListener('keydown', function(e) {
			// Prevent F12
			if (e.key === 'F12') {
				e.preventDefault();
				return false;
			}

			// Prevent Ctrl+Shift+I (Chrome DevTools)
			if (e.ctrlKey && e.shiftKey && e.key === 'I') {
				e.preventDefault();
				return false;
			}

			// Prevent Ctrl+Shift+J (Chrome DevTools)
			if (e.ctrlKey && e.shiftKey && e.key === 'J') {
				e.preventDefault();
				return false;
			}

			// Prevent Ctrl+U (View Source)
			if (e.ctrlKey && e.key === 'u') {
				e.preventDefault();
				return false;
			}
		});
	}

	return (
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<div>
				<Header />
				<Routes>
					<Route path='/' element={<LandingView />} />
					<Route path='/for-educators' element={<ForEducators />} />

					<Route path='/faqs' element={<FAQsPage />} />
					<Route path='/terms' element={<TermsOfServices />} />
					<Route path='/privacy-policy' element={<PrivacyPolicy />} />
					<Route path='/wait-list' element={<GetWaitList />} />
					<Route path='/app' element={<AppParent />}>
						{/* Redirect /app to /app/login */}
						<Route index element={<Navigate to='/app/login' />} />
						<Route path='login' element={<Login />} />
						<Route path='search' element={<SearchPage />} />
						<Route path='educators-get-started' element={<EducatorsForm />} />
						{/* Define routes for course details */}
						<Route path='courses/:id' element={<CourseDetail />} />

						{/* Define routes for educator profile */}
						<Route path='educators/:id' element={<Profile />} />
						
						
						<Route path='settings' element={<Settings />} />
						<Route path='linkedin-auth' element={<LinkedInCallback />} />
					</Route>

					{/* Redirect all unknown paths to '/' */}
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
				<Footer />
			</div>
		</GoogleOAuthProvider>
	);
}

export default App;
