import { store } from './store';
import { performSignOut } from './actions';
import { ToasterService } from '../MainUtils/ToasterService';
import { ToasterType } from '../types/interfaces';
import { ToasterDuration } from '../types/constants';
import history from '../MainUtils/history';

let timeOutObject;

const storeTokensExpTime = () => {
	try {
		if (timeOutObject?.clearTimeout) {
			timeOutObject.clearTimeout();
		}
		const refreshExpirationMillis = 24 * 14 * (60 * 60 * 1000);
		const jsonValue = JSON.stringify({
			access: Date.now() + 1 * 60 * 60 * 1000,
			refresh: Date.now() + refreshExpirationMillis,
		});

		timeOutObject = setTimeout(() => {
			store.dispatch(performSignOut());
		}, refreshExpirationMillis);

		localStorage.setItem('@tokensExpTime', jsonValue);
	} catch {
		history.push('/app/login');
		ToasterService.dispatchAddToaster({
			message: 'Sorry, an error occurred. Try logging in again',
			type: ToasterType.ERROR,
			duration: ToasterDuration.SHORT,
		});
	}
};
export const storeTokens = (value: { access: string; refresh: string }) => {
	storeTokensExpTime();
	try {
		const jsonValue = JSON.stringify(value);
		localStorage.setItem('@tokens', jsonValue);
	} catch (e) {
		history.push('/app/login'); // Navigate to the landing page
		ToasterService.dispatchAddToaster({
			message: 'Sorry, an error occurred. Try logging in again',
			type: ToasterType.ERROR,
			duration: ToasterDuration.SHORT,
		});
	}
};

export const getTokens = (): { access: string; refresh: string } | null => {
	try {
		const value = localStorage.getItem('@tokens');
		return value !== null ? JSON.parse(value) : null;
	} catch (e) {
		history.push('/app/login');
		ToasterService.dispatchAddToaster({
			message: 'Sorry, an error occurred. Try logging in again',
			type: ToasterType.ERROR,
			duration: ToasterDuration.SHORT,
		});
		return null;
	}
};



export const getTokensExpirationStatus = () => {
	try {
		const res = localStorage.getItem('@tokensExpTime');
		const parsedRes = JSON.parse(res as string);
		return res !== null
			? {
					accessExpired: Date.now() + 5000 >= +parsedRes?.access,
					refreshExpired: Date.now() + 5000 >= +parsedRes.refresh,
					isUserLoggedIn: true,
			  }
			: {
					accessExpired: true,
					refreshExpired: true,
					isUserLoggedIn: false,
			  };
	} catch (e) {
		history.push('/app/login');
		ToasterService.dispatchAddToaster({
			message: 'Sorry, an error occurred. Try logging in again',
			type: ToasterType.ERROR,
			duration: ToasterDuration.SHORT,
		});
	}
};

export const storeDeepLinkURL = (value) => {
	const jsonValue = JSON.stringify(value);
	localStorage.setItem('@deepLinkUrl', jsonValue);
};

export const getDeepLinkURL = async () => {
	try {
		const value = localStorage.getItem('@deepLinkUrl');
		return value !== null ? JSON.parse(value) : null;
	} catch (e) {
		ToasterService.dispatchAddToaster({
			message: 'Sorry, an error occurred',
			type: ToasterType.ERROR,
			duration: ToasterDuration.SHORT,
		});
	}
};
