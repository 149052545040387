import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Typography,
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	CircularProgress,
} from '@mui/material';
import { ISubscription, IUser, ToasterType } from '../../types/interfaces';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../api/profileService';
import { ToasterDuration } from '../../types/constants';
import { ToasterService } from '../../MainUtils/ToasterService';
import moment from 'moment';
interface StateProps {
	userData: IUser | null;
}

const EducatorListItem = ({
	subscription,
	setSubscriptions,
}: {
	subscription: ISubscription;
	setSubscriptions: (subscriptions: ISubscription[]) => void;
}) => {
	const getStatusText = () => {
		if (subscription.is_cancelled) {
			return `Active until ${moment(subscription.billing_date).format(
				'MMM Do'
			)}`;
		}
		return `Renews ${moment(subscription.billing_date).format('MMM Do')}`;
	};
	const [activeSubscriptionId, setActiveSubscriptionId] = useState<
		number | null
	>(null);
	const navigate = useNavigate();

	const handleSelect = async () => {
		navigate(`/app/educators/${subscription.membership_educator_id}`);
	};
	const [openResumeDialog, setOpenResumeDialog] = useState(false);

	const handleResumeDialogClose = () => {
		setOpenResumeDialog(false);
	};

	const handleResumeDialogConfirm = async (e: React.MouseEvent) => {
		e.stopPropagation();
		try {
			const subscriptions = await ProfileService.resumeSubscription(subscription.id as number);
			setSubscriptions(subscriptions)
			ToasterService.dispatchAddToaster({
				message: 'Subscription renewed successfully',
				type: ToasterType.SUCCESS,
				duration: ToasterDuration.LONG,
			});
		} catch (error) {
			ToasterService.dispatchAddToaster({
				message: 'Failed to renew subscription',
				type: ToasterType.ERROR,
				duration: ToasterDuration.LONG,
			});
		}
		setOpenResumeDialog(false);
	};

	const handleCancel = async () => {
		try {
			const subscriptions = await ProfileService.cancelSubscription(
				activeSubscriptionId as number
			);
			setSubscriptions(subscriptions);
			ToasterService.dispatchAddToaster({
				message: 'Subscription cancelled successfully',
				type: ToasterType.SUCCESS,
				duration: ToasterDuration.LONG,
			});
		} catch (error) {
			ToasterService.dispatchAddToaster({
				message: 'Failed to cancel subscription',
				type: ToasterType.ERROR,
				duration: ToasterDuration.LONG,
			});
		}
	};

	const [openDialog, setOpenDialog] = useState(false);

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const handleDialogConfirm = (e: React.MouseEvent) => {
		e.stopPropagation(); // Prevent parent click
		handleCancel();
		setOpenDialog(false);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				height: 60,
				width: '100%',
				marginTop: 2,
				paddingX: 1,
				cursor: 'pointer',
				'&:hover': { backgroundColor: '#f0f0f0' },
			}}>
			<Avatar
				onClick={handleSelect}
				src={
					subscription.membership_educator_profile_image ||
					'/images/defaultEducatorProfileImage.png'
				}
				alt={subscription.membership_educator_name}
				sx={{ width: 60, height: 60, borderRadius: 2, marginRight: 2 }}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					flex: 1,
				}}>
				<Box
					onClick={handleSelect}
					sx={{
						flex: 1,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						width: 160,
					}}>
					<Typography
						variant='body1'
						noWrap
						sx={{ fontWeight: 500, color: '#121212' }}>
						{subscription.membership_educator_name}
					</Typography>
					<Typography
						variant='body2'
						color='textSecondary'
						sx={{ opacity: 0.7 }}>
						{getStatusText()}
					</Typography>
				</Box>

				{subscription.is_cancelled ? (
					<Link
						variant='body1'
						component='button'
						onClick={() => setOpenResumeDialog(true)}
						sx={{
							cursor: 'pointer',
							fontWeight: 600,
							minWidth: 'fit-content',
							marginLeft: 2,
							zIndex: 1000,
							color: 'primary.main',
						}}>
						Resume
					</Link>
				) : (
					<Link
						variant='body1'
						component='button'
						onClick={() => {
							setOpenDialog(true);
							setActiveSubscriptionId(subscription.id);
						}}
						sx={{
							cursor: 'pointer',
							fontWeight: 600,
							minWidth: 'fit-content',
							marginLeft: 2,
							zIndex: 1000,
							color: '#2F69F8',
							textDecoration: 'none',
						}}>
						cancel
					</Link>
				)}
			</div>
			<Dialog
				open={openDialog}
				onClose={handleDialogClose}
				maxWidth={false}
				PaperProps={{
					sx: { maxWidth: 300 },
				}}>
				<DialogTitle>Cancel Subscription</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<strong>
							Are you sure you want to cancel your subscription to{' '}
							<span style={{ color: '#1976d2', fontWeight: 700 }}>
								{subscription.membership_educator_name}
							</span>
						</strong>
						? If you cancel, you will lose access to their courses and updates.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>No</Button>
					<Button onClick={handleDialogConfirm} color='error'>
						Yes, Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openResumeDialog}
				onClose={handleResumeDialogClose}
				maxWidth={false}
				PaperProps={{
					sx: { maxWidth: 300 },
				}}>
				<DialogTitle>Resume Subscription</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<strong>
							Are you sure you want to resume your subscription to{' '}
							<span style={{ color: '#1976d2', fontWeight: 700 }}>
								{subscription.membership_educator_name}
							</span>
						</strong>
						? Your subscription will be reactivated and you'll regain access to their content.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleResumeDialogClose}>No</Button>
					<Button onClick={handleResumeDialogConfirm} color='primary' variant="outlined">
						Yes, Resume
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

function ManageSubscriptionsTab({ userData }: StateProps) {
	const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		ProfileService.loadSubscribedEducators()
			.then((subscriptions) => {
				setSubscriptions(subscriptions);
			})
			.finally(() => setLoading(false));
	}, [userData]);

	if (loading)
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: 200,
				}}>
				<CircularProgress />
			</Box>
		);
	console.log('Subscriptions', subscriptions);

	if (subscriptions.length === 0) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 4 }}>
				<Typography variant='h6' color='textSecondary'>
					You don't have any active subscriptions
				</Typography>
				<Typography variant='body1' color='textSecondary'>
					Subscribe to educators to access their exclusive content and updates
				</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
			<Box>
				<Typography variant='h6' sx={{ mb: 2 }}>
					Your Subscriptions
				</Typography>
				{subscriptions.map((subscription) => (
					<EducatorListItem
						subscription={subscription}
						key={subscription.id}
						setSubscriptions={setSubscriptions}
					/>
				))}
			</Box>
		</Box>
	);
}

export default ManageSubscriptionsTab;
