import React, { useState, useEffect, useCallback } from 'react';
import { axiosDefault } from '../../api/axios';
import { GET_COMBINED_SEARCH_RESULT_URL } from '../../api/constants';
import SearchListItem from './SearchListItem';
import { ISearchResultDataObj } from '../../types/interfaces';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import RecentlySearched from './RecentlySearched';
import { loadAndStoreSearchHistory } from '../../api/searchService';

const SearchMessageDisplay = ({
	url,
	headerText,
	subHeaderText,
}: {
	url: string;
	headerText: string;
	subHeaderText: string;
}) => {
	return (
		<Box
			sx={{
				width: '90%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '35%',
				alignSelf: 'center',
			}}>
			<Avatar
				src={url}
				sx={{
					height: 120,
					width: 110,
					objectFit: 'contain',
				}}
				variant='square'
			/>
			<Typography
				sx={{
					textAlign: 'center',
					fontSize: 20,
					fontWeight: 500,
					marginTop: 2, // 15px converted to Material-UI's spacing (2*8 = 16px)
				}}>
				{headerText}
			</Typography>
			<Typography
				sx={{
					textAlign: 'center',
					fontSize: 16,
					marginTop: 2, // 15px converted
				}}>
				{subHeaderText}
			</Typography>
		</Box>
	);
};

const SearchPage = () => {
	const initialSearchCount = 20;
	const incrementBy = 10;
	const [searchQuery, setSearchQuery] = useState<string>(''); // Add type for search query
	const [results, setResults] = useState<ISearchResultDataObj[]>([]); // Use the SearchResult[] type here
	const [currentPage, setCurrentPage] = useState<number>(1); // Specify the type for currentPage
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(true);

	// Function to fetch search results
	const fetchResults = useCallback(
		async (query: string, page: number, cancelToken: any) => {
			if (!query || !hasMore) return; // If there's no query or no more data, stop the function
			setIsLoading(true);
			try {
				// Example API call with cancel token
				const response = await axiosDefault.get(
					`${GET_COMBINED_SEARCH_RESULT_URL}?search=${query.trim()}&size=${page}`,
					{ cancelToken }
				);

				const data = await response.data;

				setResults(data.results); // Append new results
				setHasMore(data.hasMore); // If no more results, stop loading more
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('Request canceled:', error.message);
				} else {
					console.error('Error fetching results:', error);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		console.log('loadAndStoreSearchHistory');
		loadAndStoreSearchHistory();
	}, []);

	useEffect(() => {
		if (searchQuery) {
			setIsLoading(true);

			// Create cancel token for axios request
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				fetchResults(searchQuery, initialSearchCount, source.token).then(() => {
					setIsLoading(false);
				});
			}, 500); // Debounce of 500ms

			// Cleanup function to cancel the request and clear the timeout
			return () => {
				source.cancel('Operation canceled by user');
				clearTimeout(timeout); // Clear the debounce timeout
			};
		} else {
			// If searchQuery is empty, reset results
			setResults([]);
			setIsLoading(false);
		}
	}, [searchQuery, fetchResults]); // Ensure fetchResults is part of dependencies

	// Function to load more results
	const loadMoreResults = () => {
		if (!isLoading && hasMore) {
			setCurrentPage((prevPage) => prevPage + 1);
			// Create cancel token for the load more request
			const source = axios.CancelToken.source();
			fetchResults(searchQuery, results.length + incrementBy, source.token);
		}
	};

	// Infinite scroll effect to load more results as the user scrolls
	useEffect(() => {
		const handleScroll = () => {
			if (
				window.innerHeight + window.scrollY >= document.body.offsetHeight &&
				!isLoading
			) {
				loadMoreResults();
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [isLoading, loadMoreResults]);

	return (
		<>
			<Typography component='h1' variant='h5' sx={{ mb: 3, fontSize: 30 }}>
				Search
			</Typography>

			<TextField
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				placeholder='What are you learning today?'
				variant='outlined' // You can choose other variants like "filled" or "standard" if you prefer
				fullWidth // Optional: makes the TextField take up the full width of its container
			/>
			<Box sx={{ maxHeight: '80%', overflowY: 'scroll', mt: 3 }}>
				{results.length > 0 && !isLoading ? (
					results.map((result, index) => (
						<SearchListItem
							data={result.data}
							dataType={result.type}
							key={index}
						/>
					))
				) : searchQuery ? (
					isLoading ? ( // Corrected this line
						<SearchMessageDisplay
							url='/images/vectors/searching.png'
							headerText='Searching...'
							subHeaderText='Please wait while we load your search results. This may take a few moments.'
						/>
					) : (
						<SearchMessageDisplay
							url='/images/vectors/no-result.png'
							headerText='No Result Found'
							subHeaderText='Try searching for a different educator, lesson, or course.'
						/>
					)
				) : (
					<RecentlySearched />
				)}
			</Box>
		</>
	);
};

export default SearchPage;
