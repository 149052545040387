import React, { useState } from 'react';
export const images = {
	semicircleBackground: '/images/vectors/landingPage/semicircleBackground.png', // Direct path
	manStudying: '/images/vectors/landingPage/manStudying.png', // Direct path
	PhoneMockup: '/images/vectors/landingPage/PhoneMockup.png', // Direct path
	Rockstar: '/images/vectors/landingPage/Rockstar.png', // Direct path
};

import { featuresData, memojies } from './data';
import FadeInSection from '../Shared/FadeInSection';
import ScalableSection from '../Shared/ScalableSection';
import './LandingView.css';
import Button from '@mui/material/Button';

function LandingView() {
	const [features] = useState(featuresData);
	const mappedFeatures = features.map(({ name, description, image }, i) => {
		return (
			<div className='feature-contents' key={i + name}>
				<img src={image} alt={name + ' icon'} className='feature-image' />
				<div className='feature-texts'>
					<h5 className='feature-name'>
						{name}
						{[1].includes(i) && <p className='beta-icon'>coming soon</p>}
					</h5>

					<p className='feature-description'>{description}</p>
				</div>
			</div>
		);
	});
	return (
		<main className='home-container'>
			<div className='curved-bg curved-bg-blue' style={{ marginBottom: '0px' }}>
				<FadeInSection>
					<section className='section-container landing-section'>
						<div className='pitch'>
							<h3 className='pitch-heading'>
								Audio learning for wherever, whenever
							</h3>
							<p className='pitch-paragraph'>
								{`Why hit pause on life to learn something new? With Memoryze, you can dive into any topic while you're on the go. Just choose a lesson, plug in your headphones, and start learning—no matter what you're doing.`}
							</p>
							<Button
								variant='outlined'
								color='primary'
								className='get-the-app-btn'
								onClick={() => {
									//todo
								}}>
								Get Early Access!
							</Button>
						</div>
						<img
							src={images.manStudying}
							alt='man-studying'
							className='top-image'
						/>
					</section>
				</FadeInSection>
			</div>
			<ScalableSection scaleStart={0.8} scaleEnd={1.4}>
				<h4 className='features-heading'>Why Memoryze?</h4>
				<section className='section-container features-section'>
					<div className='features'>
						<div className='feature-contents-container'>{mappedFeatures}</div>
					</div>
					<img
						src={images.PhoneMockup}
						alt='phone mockup'
						className='phone-mockup'
					/>
				</section>
			</ScalableSection>
			<ScalableSection scaleStart={0.7} scaleEnd={1.4}>
				<h4 className='section-heading sake-of-learning-header'>
					Learn for the sake of learning
				</h4>
				<section className='section-container rock-star-section'>
					<img src={images.Rockstar} alt='rock star' className='rock-star' />
					<div className='rock-star-text'>
						<h5 className='rock-star-heading'>Learn While You Live</h5>
						<p className='rock-star-description'>{`Memoryze seamlessly integrates learning into your everyday routine, turning downtime into productive moments. With audio lessons designed to fit into your busy schedule, you can learn at your own pace without missing a beat. Whether you’re commuting, exercising, or relaxing, there’s always time to learn.`}</p>
						<a href='/wait-list' className='get-the-app-btn'>
							Get early access!
						</a>
					</div>
				</section>
			</ScalableSection>
			<ScalableSection>
				<h4 className='section-heading memoji-section-header'>
					Join the Movement
				</h4>
				<p className='section-subheading'>
					{`Hundreds have already signed up for early access to Memoryze. Here's what some of them are saying:`}
				</p>
				<section className='section-container memoji-section'>
					{memojies.map(({ name, image, comment }, i) => {
						return (
							<div className='memoji-card' key={i + name}>
								<img src={image} alt='a memoji' className='memoji' />
								<div className='memoji-texts-container'>
									<p className='memoji-name'>{name}</p>
									<p className='memoji-comment'>{comment}</p>
								</div>
							</div>
						);
					})}
				</section>
			</ScalableSection>
		</main>
	);
}

export default LandingView;
