export const learners = [
	{
		question: 'How do I sign up?',
		answer:
			'Memoryze is still in the works, but early access to our beta version will be available soon! Want to be among the first to experience it? Click here to reserve your spot and get early access.',
	},
	{
		question: 'How long are the courses?',
		answer:
			'We’ve designed our courses to be both informative and engaging, with each lesson lasting between 15 to 40 minutes. This way, you can learn effectively without losing focus.',
	},
	{
		question: 'How does Memoryze work?',
		answer:
			'Imagine your favorite audio streaming platform—but instead of music and podcasts, you’re tuning into captivating lessons on topics that interest you. Memoryze makes learning as easy as pressing play.',
	},
	{
		question: 'Can anything really be taught through audio?',
		answer:
			'Absolutely! While some subjects may require hands-on practice, every topic has concepts and theories that can be effectively taught through audio. Whether it’s theory-heavy subjects or general life skills, there’s always something to learn via audio.',
	},
	{
		question: 'Will the app be free?',
		answer:
			'For now, Memoryze is completely free to use. We might introduce premium features down the line, but the core learning experience remains free.',
	},
	{
		question: 'Will there be certificates at the end of a course?',
		answer:
			'At Memoryze, our focus is on the joy of learning and gaining knowledge for its own sake, rather than on certification. Currently, we don’t offer certificates, as we believe that learning is most powerful when driven by curiosity and passion.',
	},
	{
		question: 'How do I delete my account?',
		answer:
			'To delete your account, first log in to your account on this website. Then, navigate to your settings and click on the accounts tab. You will see the "Delete account" accordion. Follow the instructions provided to complete the deletion process.',
	},
];

export const educators = [
	{
		question: 'How long should my courses be?',
		answer:
			'We recommend keeping your lessons between 15 to 40 minutes. This sweet spot ensures that learners stay engaged without zoning out, allowing them to absorb and enjoy every bit of your knowledge.',
	},
	{
		question: 'How do I record my courses?',
		answer:
			"It’s easier than you think! Once you sign up as an educator, we’ll provide you with all the resources you need to create your courses using just your mobile phone. We're here to support you every step of the way!",
	},
	{
		question: 'Who owns the rights to my courses?',
		answer:
			"You do—100%. Your content remains your intellectual property, and you have full control over how it's used.",
	},
	{
		question: 'How do I get paid?',
		answer:
			'You earn royalties whenever a learner subscribes to any of your courses. While courses will be monetized after our official launch, you’ll be set to start earning once the app moves out of the beta phase',
	},
	{
		question: 'Do I have to pay any fees to become an educator?',
		answer:
			'Absolutely not! Joining Memoryze as an educator is completely free. Your expertise is the only investment needed.',
	},
	{
		question: 'Will Memoryze help promote my course?',
		answer:
			'We’re here to give you a boost! While we’ll support your promotional efforts, the success of your course is largely in your hands. Focus on delivering high-quality, engaging content and think creatively about your marketing strategy—together, we’ll make your course shine.',
	},
	{
		question: 'What can I teach on Memoryze?',
		answer:
			'Anything you’re passionate about! Whether it’s a language, an academic subject, or essential life skills, there’s a place for your course on Memoryze.',
	},
	{
		question: 'Are there any requirements my course must meet?',
		answer:
			'Yes, to ensure the highest quality for our learners, we have specific guidelines for courses. You’ll receive a detailed course guideline upon signing up as an educator, covering everything you need to know.',
	},

	{
		question: 'How do I delete my account?',
		answer:
			'To delete your account, first log in to your account on this website. Then, navigate to your settings and click on the accounts tab. You will see the "Delete account" accordion. Follow the instructions provided to complete the deletion process.',
	},
];
